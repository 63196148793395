/* matogo */

body {
    font-family: Arial, Helvetica, sans-serif;
}
body.not-logged-in #block-commerce-cart-cart .line-item-summary-checkout {
  display: none;	
}
.product-dimension-price-complete {
	padding-top: 12px;
	padding-bottom: 20px;
}
.field-name-field-beschreibung.field-type-text-with-summary {
	padding-bottom: 12px;
}
.field-name-field-rahmenabwicklung {
	padding-bottom: 15px;
}
.commerce-product-field-field-erweiterungen {
	padding-top: 20px;
}	
.field-name-field-kategorie {
	padding-top: 20px;
	.field-item {
		@media (max-width: @screen-xs-max) {
			padding-bottom: 10px;
		}
	}
}
.product-dimension-price .label,
.product-dimension-price-complete .label,
.dimension .label,
.dimension span.label {
    color: #333333;
}
.group-weiterverarbeitung .input-group-addon {
    padding: 0; 
    background-color: transparent; 
    border: none;
}

.matogo-font {
    font-family: matogo, Helvetica, Arial, sans-serif;
    color: #333333;
}
.matogo-point {
    color: @matogo-color;
}
.matogo-doc-download {
	float: right;   
}
.matogo-einzug {
	margin-left: 20px;	
}
.einheit:before {
	content: ' ';
}
.node-type-system-50-vorkonfiguriert .commerce-product-field-commerce-price {
	padding: 20px 0px;
}
table.matogo-vk {
	width: 98%;
}
td.matogo-vk-1 span,
span.rental-price {
	font-size: 125%;
	font-weight: 600;
}
td.matogo-vk-2 {
	font-size: 112%;
	text-align: right;
}
div.aufbau {
	font-weight: bold;
}
.node.matogo-fixed-price .matogo-from .flexy-amount:before {
	content: '';
}	
.matogo-from .flexy-amount:before,
.node-system-50.node-teaser .field-name-commerce-price .field-item:before,
.node-system-50-messestand.node-teaser .field-name-commerce-price .field-item:before,
.node-system-50-zubehoer.node-teaser .field-name-commerce-price .field-item:before,
.node-system-50-pw.node-teaser .field-name-commerce-price .field-item:before,
.node-stoff-unbedruckt.node-teaser .field-name-commerce-price .field-item:before,
.node-textildruck.node-teaser .field-name-commerce-price .field-item:before,
.node-textildruck-fahne.node-teaser .field-name-commerce-price .field-item:before,
.node-system-50-vorkonfiguriert.node-teaser .field-name-commerce-price .field-item:before,
.node-textildruck-system-50.node-teaser .field-name-commerce-price .field-item:before,
#block-views-random-produkt-block .flexy-amount:before{
	content: 'ab ';
}
.node-stoff-unbedruckt.node-teaser .field-name-commerce-price .field-item:after,
.node-textildruck.node-teaser .field-name-commerce-price .field-item:after,
.node-textildruck-fahne.node-teaser .field-name-commerce-price .field-item:after {
	content: '/m²';
}
.node.node-system-50-vorkonfiguriert {
	.field-name-field-beschreibung-2 {
		padding: 15px 0;
	}
	.field-type-commerce-product-reference {
		padding-top: 0;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 20px;
	}
	h4 {
		font-size: 14px;
	}
}
.node-teaser .field-type-text-with-summary h3 {
	margin-top: 0px;	
}
.navbar-brand {
	height: 45px;
	.matogo-font {
    	font-size: 2em;
	}
}
.navbar-nav li a.active {
	background-color: #f0f0f0;
}

.agb-title {
    font-weight: bold;
}
.agb-paragraph {
    padding-bottom: 0.7em;
}
.agb-text {
    padding-left: 1.6em;
}
.icon-pdf {
    background-image: url('/sites/matogo.de/files/icons/pdf.gif');
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 20px;
}
.messestand-schenkel-wrapper .form-group.field-name-field-stoffart {
	margin-bottom: 2px;
}
.field-name-field-relevante-katalogeintraege {
	margin-top: 14px;
}
.view-produktkatalog {
  .glyphicon-chevron-left,
  .glyphicon-chevron-right {
  	display: none;
  }
  .carousel-control.left,
  .carousel-control.right {
  	background-image: none;
  }
}
.view-random-produkt .node-textildruck div.field-name-commerce-price span.flexy-amount:after,
.view-random-produkt .node-textildruck-fahne div.field-name-commerce-price span.flexy-amount:after,
.view-random-produkt .node-fahne div.field-name-commerce-price span.flexy-amount:after,
.view-random-produkt .node-stoff-unbedruckt div.field-name-commerce-price span.flexy-amount:after,
tr.base-price div.field-name-commerce-price span.flexy-amount:after {
    content: '/m²';
}
.view-random-produkt .node-system-50-pw div.field-name-commerce-price span.flexy-amount:after,
.view-random-produkt .node-system-50 div.field-name-commerce-price span.flexy-amount:after  {
    content: '/m';
}

p.no-content.System-80 {
    display: none;
}
a.ctools-modal-media-file-edit {
    display: none;
}
.matogo-netto .flexy-amount {
	font-size: 130%;
}
.product-dimension-price {
	margin: 12px;
}
.product-dimension-price table,
.product-dimension-price-complete table.standard {
	background-color: @matogo-color;
	/* funktioniert nicht !??
	padding-top: 10px;
	padding-bottom: 10px;
	*/
}
aside.col-sm-3 {
	#block-block-3 .product-dimension-price table{
      max-width: 200px;
	}
	@media (max-width: @screen-xs-max) {
		margin-top: 20px;
	}
}
.product-dimension-price td.first-col,
.product-dimension-price-complete td.first-col {
	padding-left: 5px;
}
.product-dimension-price td.second-col,
.product-dimension-price-complete td.second-col {
	padding-right: 5px;
}
.node-teaser .field-name-commerce-price {
	font-size: 20px;
	padding-top: 10px;
}
.view-commerce-cart-block .line-item-summary .line-item-total-raw {
	font-size: 20px;
}
.view-commerce-addressbook table {
  border-collapse: collapse;
  border: 1px solid black;
  margin-top: 7px;
  td {
	border: 1px solid black;
	padding: 5px;
  }
}

.help-block-up {
  margin: 0 0 15px 0;
}

.field-name-field-verwandte-artikel {
	margin-top: 30px;
}

.line-item-summary .line-item-quantity {
	margin-right: 10px;
}
/*
aside.col-sm-3 #block-block-3 .product-dimension-price .flexy-amount {
    background-color: @matogo-color;
}
*/
section#block-block-3 {
    max-width: 200px;
}

#views-form-commerce-cart-form-default .input-group-addon {
    display: none;
}
.commerce-add-to-cart .btn-default,
#matogo-missing-printmaster-form #edit-submit.btn-default,
#commerce-checkout-form-checkout .checkout-continue.btn-default,
#commerce-checkout-form-shipping .checkout-continue.btn-default,
#commerce-checkout-form-review .checkout-continue.btn-default,
.commerce-line-item-actions .btn-info,
#user-register-form .btn-success {
	color: #fff;
	font-weight: bold;
	background-color: @matogo-button-background-color;
	border-color: @matogo-button-border-color;
}
.checkout-order-number {
	font-weight: bold;	
}
#matogo-missing-printmaster-form .media-widget {
    padding-bottom: 10px;	
}
.printmaster-quantity-descr {
	padding-top: 10px;
}
.needed-printmaster {
	font-weight: bold;
	padding-top: 15px;
	input.upload,
	button.attach {
		display: none;
	}
	a.browse {
		display: inline;
	}
}
.media-thumbnail, .media-thumbnail .img-responsive {
	min-height:	10px;
}
.matogo-price-wrapper {
	margin-top: 10px;
}
.matogo-price-prefix {
	text-align: left;
}
.no-margin-b {
	margin-bottom: 0px;
}
.rwd-break {
	@media (min-width: 200px) {
		display: block;
	}
	@media (min-width: @screen-md-min) {
		display: none;
	}
}
.matogo-left .left-or-right {
	@media (max-width: @screen-xs-max) {
		display: none;
	}
}
.matogo-right .left-or-right {
	@media (min-width: @screen-sm-min) {
		display: none;
	}
}
span.order-billy-pdf {
	padding-left: 1em;	
}
.zahlung-sofort { display: none; }

.component-total, .line-item-total-raw, .price,
.views-field-commerce-order-total,
.views-field-commerce-total { 
	white-space: nowrap;
}
.views-field-commerce-order-total {
	text-align: right;
}
.flexy-amount {
	width: auto;
	padding-right: 2px;
}
.flexy-title {
	padding-left: 2px;
	padding-right: 6px;	
}
.flexy-item {
	white-space: nowrap;
}
.node.view-mode-full .field-commerce-price .plus-shipping, 
.node .field-commerce-price .plus-shipping{
    float: right;
}
.node .field-type-commerce-product-reference {
	padding-top: 30px;
}
.product-dimension-price-complete .first-col {
    vertical-align: top;
}
.product-dimension-price-complete hr {
    color: black;
    width: 70%;
    margin-top: 3px;
    margin-bottom: 3px;
    border-top-color: grey;
}
.suffix-price .second-col {
    text-align: right;
}
.printmaster-quantity {
	font-weight: bold;
}
fieldset.collapsible legend.panel-heading a.panel-title:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
fieldset.collapsible legend.panel-heading a.panel-title.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";    /* adjust as needed, taken from bootstrap.css */
}
.panel-body.fade.collapse {
	opacity:100;
	display: table;
}
body.front h1 {
	display: none;
}
body.front .region-content h3 {
	font-size: 18px;
}
body.front .region-content p a,
body.front .region-content p a:hover,
body.front .region-content p a:focus,
.yamm-content a,
.yamm-content a:focus{
	color: inherit;
	text-decoration: none;
}
.yamm-content a:hover {
	color: #23527c;
	text-decoration: none;
}
.yamm-content .divider-below {
	border-bottom: solid 1px;
	padding-bottom: 3px;
	width: 100%;
	display: block;
	height: auto;
}
.yamm-content {
	.lvl1 {
		padding-left: 20px;
	}
	.lvl2 {
		padding-left: 30px;
	}
	.submenu-title {
		font-weight: bold;
	}
	.row {
		padding-bottom: 3px;
	}
	summary.submenu-title {
		cursor: pointer;
	}
	summary.submenu-title:hover {
		color: @link-color;
	}
}
.yamm .dropdown-menu {
  background-color: whitesmoke;	
}
.yamm {
	z-index: 1002;
}
.dropdown.yamm-hw .dropdown-menu {
/*
  left:-webkit-calc(100% - 350px);
  left:-moz-calc(100% - 350px);
*/
  left: 50%;
  right: 0;
}
.dropdown.yamm-66 .dropdown-menu {
/*
  left:-webkit-calc(100% - 350px);
  left:-moz-calc(100% - 350px);
*/
  left: 66%;
  right: 0;
}
.navbar > .container .row .navbar-brand, 
.navbar > .container-fluid .row .navbar-brand {
	margin-left: 0;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus,
.yamm-content a:hover {
	color: @link-color;
}
.successor-row {
	margin-top: 20px;
	border-top: solid 2px;
}
#admin-menu {
	height: 21px;
}
body.admin-menu {
  @media (min-width: 200px) {
    margin-top: 100px !important;
  }		
  @media (min-width: @screen-xs-min) {
    margin-top: 80px !important;
  }		
  @media (min-width: @screen-sm-min) {
    margin-top: 60px !important;
  }
  @media (min-width: @screen-md-min) {
    margin-top: 40px !important;
  }
  @media (min-width: @screen-lg-min) {
    margin-top: 40px !important;
  }
}
img.flags {
	padding-right: 5px;
}
.region-sidebar-first h2,
.region-sidebar-second h2 {
	font-size: 18px;
	font-weight: bold;
}
.first-affix.affix {
	top: 0;
	z-index: 1000;
	width: 100%;
	background-color: #f8f8f8;
}
.second-affix.affix {
	top: 0;
	z-index: 1002;
}
.third-affix.affix {
	top: 50px;
	//top: 110px; // Versuch für neuen header
	z-index: 1000;
	width: 100%;
	background-color: white;
}
.stickynav-active {	
  @media (max-width: @screen-sm-min) {
    right: 0px;
  }	
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
.col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
.col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
.col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
.col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
.col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 16px;
    padding-right: 14px;
}

.tablesaw-stack td .tablesaw-cell-label, .tablesaw-stack th .tablesaw-cell-label {
    width: 40%;
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    margin-bottom: (@line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: normal;
            padding: 8px 0px 8px 8px;
            .img-responsive {
            	min-width: 26px;	
            }
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}
/*
.faq-question {
	font-size: 18px;
}
.faq-answer {
	padding-bottom: 10px;
}
*/
.einrahmung {
	padding-left: 10px;
	border: solid 1px;
}
.einrahmung h4 {
	font-size: 14px;
}
.field-name-field-beschreibung,
.taxonomy-term-description {
	margin-top: 10px;
}
a.more-link {
	padding-left: 5px;
}
/* faq 
.faq-detailed-question {
	padding: 5px 0px;
	font-weight: bold;
}
.field-name-field-fragenkategorie {
	padding-top: 5px;
} 
*/
/* webform */
.form-item-submitted-zusatzdaten.form-group:last-child {
	margin-bottom: 10px;
	padding: 10px 10px 5px 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	.form-control {
		height: auto;
	}
	button {
		margin-top: 10px;
	}
}
#commerce-checkout-form-shipping .form-type-radio label p {
	text-align: center;
}
/* Video */
.embed-video {
	border: 2px solid grey !important;
}
/* Reverse charge */
body.reverse-charge .incl-vat {
	display: none;
}
/* Field Slideshow */
.field-slideshow-caption {
	background-color: white;
}

/* Carousel */
/*
.carousel-caption {
	background-color: rgba(@matogo-color, 0.5);
	color: black;
}
*/
.carousel-indicators li {
	@media (max-width: @screen-xs-max) {
		width: 8px;
		height: 8px;
		border-radius: 8px;
		margin: 2px;
	}
}
.carousel-caption {
	@media (max-width: @screen-xs-max) {
		h3 {
			font-size: 18px;
		}
		p {
			font-size: 12px;
		}
	}
}
/* Carousel  Überblendung */
.carousel-inner .item {
    opacity:1;
}
.carousel-inner .item.active.right, .carousel-inner .item.next {
    left: 0;
 
	-webkit-transition: opacity 0.7s ease-in-out;
	-moz-transition: opacity 0.7s ease-in-out;
	-ms-transition: opacity 0.7s ease-in-out;
	-o-transition: opacity 0.7s ease-in-out;
   transition: opacity 0.7s ease-in-out;	
	opacity:0;
     transform: translate3d(0, 0, 0);
}
.carousel-inner .item.active.left, .carousel-inner .item.prev {
    left: 0;
	-webkit-transition: opacity 0.7s ease-in-out;
	-moz-transition: opacity 0.7s ease-in-out;
	-ms-transition: opacity 0.7s ease-in-out;
	-o-transition: opacity 0.7s ease-in-out;
    transition: opacity 0.7s ease-in-out;
	opacity:0;
    transform: translate3d(0, 0, 0);
}
.carousel-inner .item.active, .carousel-inner .item.next.left,  .carousel-inner .item.prev.right {
    left: 0;
   	opacity:1;
}
.carousel-inner > .item > img, .carousel-inner > .item > a > img {
	margin-left: auto;
	margin-right: auto;
}
footer .nav > li > a {
	padding: 6px 11px;
}
.luecke-r {
	@media (min-width: @screen-md-min) {
    	border-right: solid grey 1px;
	}
	+ li {
		@media (max-width: @screen-sm-max) {
			clear: left;
		}
	}
}
.region-footer p:last-child {
    margin-top: 20px;
}
.lead {
	clear: left;
	font-size: 12px;
	padding-left: 15px;
	@media (max-width: @screen-xs-max) {
		padding-bottom: 6px;
	}
}
form.commerce-add-to-cart .field-name-field-produkt-eigenschaften-os {
	.wysiwyg-toggle-wrapper,
	fieldset.filter-wrapper {
		display: none;
	}
}

/* cart popup */
.cart-icon-wrapper {
	z-index: 1003;
}
.cart-icon {
  background: url("/sites/matogo.de/files/icons/warenkorb.png") no-repeat;
  width: 50px;
  height: 50px;
}
.cart_popup_count {
  float: left;
  font-size: 14px;
  line-height: .85;
  margin-top: 10px;
  margin-left: 0px;
  color: #777;
}
#block-commerce-popup-cart-commerce-popup-cart div.wrapper {
  @media (min-width: @screen-xs-min) {
    top: -76px;
	left: -50px;
  }		
  @media (min-width: @screen-sm-min) {
    top: -76px;
	left: -30px;
  }
}
/* versuch für neuen header
#block-commerce-popup-cart-commerce-popup-cart div.wrapper.affix {
  @media (min-width: @screen-xs-min) {
    top: -24px;
  }		
}
*/
.breadcrumb {
	background-color: inherit;
	width: 80%;
}

.region-content ul.field-slideshow-pager {
	padding-top: 15px;
}

/* Font settings */
.small-font {
	font-size: 12px;
}

/* Views */
.view-messestand-galerie .views-field-field-image {
	padding-bottom: 20px;
}

/* FAQ */
.ui-widget {
	font-family: inherit;
}

/* Payment */
.commerce-paypal-icon,
.commerce-paypal-ec-icon {
	top: 0px;
}
.commerce-paypal-icons .commerce-paypal-icon {
	float: left;
}
#edit-commerce-payment-payment-method {
	margin-bottom: 20px;
}
p.preisangabe {
	background-color: @matogo-color;
	padding: 10px;
	.netto {
		font-weight: bold;
		font-size: 1.5em;
	}
}
#payment-details {
	background-color: @matogo-hinweis;
	margin-top: 50px;
}

/* simplenews */
#simplenews-subscriptions-page-form .form-item-mail {
	margin-top:	10px;
}

#matogo-order-delete-confirm .entity-commerce-order {
	padding-bottom: 10px;
}

/* Landing pages */
.node-lp {
	img {
		margin-left: 8px;
		margin-right: 8px;
		@media (min-width: @screen-sm-min) {
			max-width: 50%;
		}
	}
	.col-sm-6 img {
		max-width: 100%;
	}
	h4 {
		padding-top: 10px;
	}
}
.node-lp .inline img,
.node-lp img.panorama {
	@media (min-width: @screen-sm-min) {
		max-width: 100%;
	}
}
.node-lp img.hoch {
	@media (min-width: @screen-sm-min) {
		max-width: 33%;
	}
}
.node-lp .inline .caption {
	font-weight: bold;
	position: relative; 
    top: -15px; 
}
.node-lp {
	.row {
		margin-left: 0px;
		margin-right: 0px;
	}
	.btn-primary {
		margin: 4px;
	}
	.btn-primary a {
		color: white;
	}
}
.produktdatenblatt {
	#block-system-main {
		.row {
			margin-left: 0px;
			margin-right: 0px;
			margin-bottom: 15px;
		}
		table {
			width: 100%;
			td, th {
				vertical-align: bottom;	
				p {
					padding-left: 4px;
					padding-right: 4px;	
				}
			}
		}
		image {
			max-height: 350 px;
		}
		h4 {
			margin-top: 20px;
		}
	}
}
a.nextpage,
a.nextpage:hover,
a.nextpage:active,
a.nextpage:focus {
	text-decoration: none;
//	cursor: inherit;
    cursor: default;
    pointer-events: none;
    color: inherit;
}
span.nextpage {
    color: white;
    font-size: 0;	
}

#edit-commerce-shipping-shipping-service .radio {
	margin-top: 0;
}
ol.commerce-checkout-progress li.active {
	font-weight: bold;
}
.field-name-field-verweis.field-type-entityreference > .field-label {
	padding-top: 22px;
	font-size: 18px;
	clear: both;
}
@media print {
	#sliding-popup,
	div.carousel-inner .item,
	div.carousel-inner img,
	.carousel-control {
		display: none;
	}
}
/*
body.page-user-orders .field-name-commerce-customer-eu-vat-rc {
    display: none;	
}
*/

// Lexikon
//.anchor {  // um Sprungziel nicht von fixed Header zu verdecken
//  display:block;
//  padding-top:100px;
//  margin-top:-100px;
//}

// faqfield
.field-type-faqfield {
	input{
		display: none;
	}
	.faqfield-input:checked {
		+ label {
			h3 .glyphicon-triangle-right {
				display: none;
			}
			h3 .glyphicon-triangle-bottom {
		    	display: inline-block;
		    }
		}
		~div.faqpanel {
			border-left: #d3d3d3 solid 1px;
			border-right: #d3d3d3 solid 1px;
			border-bottom:  #d3d3d3 solid 1px;
		}
	}
    .glyphicon-triangle-bottom {
    	display: none;
    }
	.faqpanel {
	  margin: 0 auto;
	  height: 0;
	  overflow:hidden;
	  //background-color: white;
	  line-height: 1.4;
	  //padding: 0 20px;
	  box-sizing: border-box;
	  
	  transition: all 2s linear;
	  -webkit-transition:  2s ease-in-out;
	  -moz-transition:  2s ease-in-out;
	  -ms-transition:  2s ease-in-out;
	  -o-transition:  2s ease-in-out;
	  
	}
	input:checked~.faqpanel {
	  height: auto;
	  color: #333;
	 // padding: 20px;
	 
	  transition: all 2s linear;
	  -webkit-transition:  2s ease-in-out;
	  -moz-transition:  2s ease-in-out;
	  -ms-transition:  2s ease-in-out;
	  -o-transition:  2s ease-in-out;
	 
	}
	.faqanchor {
		position: relative;
		top: -300px;
	}
	a:hover {
		text-decoration: none;
	}
	label.faqfield-label {
		width: 100%;
		margin: 0;
	}
	h3.faqfield-question {
		color: #555;
		font-size: 14px;
		line-height: 21px;
		border: 1px solid #d3d3d3;
		background: #e6e6e6 url(/sites/all/modules/jquery_update/replace/ui/themes/base/minified/images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x;
		margin-top: 5px;
		margin-bottom: 0px;
    	padding: .5em .5em .5em .7em;
    	.glyphicon {
    		padding-right: 10px;
    	}
	}
	h3.faqfield-question:hover {
		text-decoration: none;
	}
	.faqfield-answer {
		padding: 1em;
	}
}
/*
.faqfield-question a.anchor,  //alt
.faqanchor {
	position: relative;
	top: -300px;
}
*/
dt a.anchor, #filter.anchor {
	position: relative;
	top: -180px;
}

//dt {
//  z-index: 0;
//  [id]:before { // um Sprungziel nicht von fixed Header zu verdecken
//	  display: block; 
//	  content: " "; 
//	  margin-top: -220px; 
//	  height: 220px; 
//	  visibility: hidden;  
//  }
//}
//.lexicon-term.active[data-title]:hover::after {
//  content: attr(title);
//  position: absolute;
//  top: 100%;
//  left: 0;
//  background-color: yellow;
//}
p.lexicon-related {
	margin-bottom: 3px;
}
.lexicon-description {
	display: none;
}
.lexicon-introduction {
	font-size: 120%;
}
.lexicon-list dt {
	padding-top: 8px;
	margin-top: 7px;
	border-top: 1px dotted @matogo-color;
}
a.lexicon-term {
	color: inherit;
	border-bottom: 1px dotted @matogo-color;
}
.lexicon-extralinks a {
	background-color: inherit;
}
#glossar .col-xs-2,
#glossar .col-xs-10 {
	padding-right: 0px;
}
// Produktdatenblatt
//#node-638 {
//	table {
//		width: 100%;
//		td {
//			vertical-align: bottom;	
//		}
//	}
//	image {
//		max-height: 350 px;
//	}
//	.row {
//		margin-left: 0px;
//		margin-right: 0px;
//	}
//	h4 {
//		margin-top: 20px;
//	}
//}
// ebay kleinanzeigen
div.view-ebay-kleinanzeigen,
div.view-eka-gerald,
div.view-eka-thomas
{
	.past-date {
		color: red;
	}
	.next-future {
		color: green;
	}
}

// accordion
.panel-heading .accordion-toggle:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: grey;
}
.panel-heading .accordion-toggle.collapsed:after {
    content: "\e080";
}

.view-zufaellige-messe .views-field-field-messetermin {
	padding: 12px 0px;	
}
.messe-disclaimer {
	font-size: 11px;
	position: relative;
    top: -15px;
}
.other-fairs {
	text-align: right;
}
.txt-mittig {
	text-align: center;
}
.txt-indent-10 {
	text-indent: 10px;
}
	